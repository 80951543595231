import "./Calendar.css";

const Calendar = () => {
    return (
        <>
        <div 
        >
            <iframe 
                title="St. Louis Brews Calendar"
                src="https://www.google.com/calendar/embed?title=St.%20Louis%20Brews%20Calendar&amp;height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=n2hc48vhiocg5dla8qmqjeelq8%40group.calendar.google.com&amp;color=%23691426&amp;src=m12993m72vpu91r3jddk5vasa4%40group.calendar.google.com&amp;color=%23125A12&amp;src=hk010r2f0q5nso4pa2qg6ilrto%40group.calendar.google.com&amp;color=%237A367A&amp;src=u57l0787ggj95kh2ud80ounkro%40group.calendar.google.com&amp;color=%238C500B&amp;src=r9fqclba352e9ak3ouhkc9l7l0%40group.calendar.google.com&amp;color=%232F6309&amp;ctz=America%2FChicago"
                class="google-calendar-iframe"
                ></iframe>
        </div>
        </>
    );
  };
  
  export default Calendar;


  